<template>
  <div :class="d.right" style="min-height: 500px;display: flex; flex-direction: column;">
    <div>
      <div :class="d.icon">
        <v-icon
          color="black"
          @click="closeDialog"
          class="cursor-pointer"
          size="18px"
          >fas fa-times</v-icon
        >
      </div>
      <div v-if="isAdmin" style="display: flex;flex-direction: column;height: 100%;">
        <div :class="d['top-section']">
          <h2 style="font-size: 14px;">AI Category Tags</h2>
            <div :class="d['ai-tag-container']">
                <div v-for="(aiTag, index) in aiTags" :key="aiTag.id" :class="d['ai-tag']">
                    <span :class="d['ai-tag-name']">{{ aiTag.name }}</span>
                </div>
            </div>
        </div>
        <div :class="d['divider']"></div>
        <div :class="d['bottom-section']">
            <div style="display: flex;align-items: center;">
                <h2 style="font-size: 14px;width: 300px;">Manual Tagging</h2>
                <input type="text" v-model="searchQuery" placeholder="Search" style="border: 1px solid #ccc;border-radius: 4px;width: 200px;">
            </div>
          <div :class="d['content']">
              <ul :class="d['tag-list']">
                <li v-for="(tag, index) in filteredTags" v-if="tag.id < 1000" :key="tag.id">
                  <div :class="[d['tag-container'], { 'is-selected': (selectedId === tag.id) }]" @click="toggleSelection(tag, $event)">
                    <div :class="d['tag-text']">
                        <input type="checkbox" id="allhorns" name="allhorns" :value="tag.id"
                               :checked="checkTags.includes(tag.id)"
                               @change="handleCheckboxChange($event, tag.id)"/>
                        <span :class="d['tag-name']">
                        {{ tag.name }}
              </span>
                    </div>
                    <span v-if="tag.children && tag.children.length" :class="d['toggle-btn']">
                        <button>
                            <svg v-if="tag.showChildren" width="11" height="7" viewBox="0 0 8 5" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
<path d="M4.1604 0.0742188C3.96899 0.0742188 3.80884 0.144531 3.64868 0.304688L0.758057 3.26172C0.640869 3.38281 0.582275 3.52344 0.582275 3.69141C0.582275 4.03906 0.859619 4.32031 1.20337 4.32031C1.37524 4.32031 1.5354 4.25 1.66431 4.12109L4.16431 1.54297L6.6604 4.12109C6.78931 4.25 6.94946 4.32031 7.11743 4.32031C7.46118 4.32031 7.73853 4.03906 7.73853 3.69141C7.73853 3.51953 7.67993 3.37891 7.56274 3.26172L4.67212 0.304688C4.51978 0.148438 4.35571 0.0742188 4.1604 0.0742188Z"
      fill="#FF617E"/>
</svg>
                            <svg v-else width="11" height="7" viewBox="0 0 8 5" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
<path d="M4.1604 4.92578C3.96899 4.92578 3.80884 4.85547 3.64868 4.69531L0.758057 1.73828C0.640869 1.61719 0.582275 1.47656 0.582275 1.30859C0.582275 0.960938 0.859619 0.679688 1.20337 0.679688C1.37524 0.679688 1.5354 0.75 1.66431 0.878906L4.16431 3.45703L6.6604 0.878906C6.78931 0.75 6.94946 0.679688 7.11743 0.679688C7.46118 0.679688 7.73853 0.960938 7.73853 1.30859C7.73853 1.48047 7.67993 1.62109 7.56274 1.73828L4.67212 4.69531C4.51978 4.85156 4.35571 4.92578 4.1604 4.92578Z"
      fill="#B8B8B8"/>
</svg>

                        </button>
                    </span>
                  </div>
                  <div v-if="tag.showChildren && tag.children && tag.children.length" :class="d['child-container']">
                    <ul :class="d['child-list']">
                      <li v-for="(child, childIndex) in tag.children" :key="child.id">
                        <div v-if="(showChecked && checkTags.includes(child.id) || !showChecked)" :class="[d['child-item'],{ 'is-selected': (selectedId === child.id) }]" @click="toggleSelection(child, $event)">
                            <div :class="d['tag-text']">
                            <input type="checkbox" id="childhorns" name="childhorns" :value="child.id"
                                   :checked="checkTags.includes(child.id)"
                                   @change="handleCheckboxChange($event, child.id)"/>
                          <span :class="d['tag-name']">
                                {{ child.name }}
                            </span>
                            </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </li>
              </ul>
          </div>
            <div v-if="isAdmin">
                <div style="border-top: 1px solid #ccc;"></div>
                <div style="display: flex; padding: 5px;align-items: center;">
                <div style="width: 300px;display: flex;">
                <input type="radio" id="radiohorns" name="radiohorns" :value="showChecked" style="width: 15px;height: 15px;margin-top: 3px;margin-right: 5px;"
                       :checked="showChecked"
                       @click="toggleFilter()"/>
                    <span>Only show checked tags</span>
                </div>
                <custom-button
                        :loading="loading"
                        @click="relatFeedAndTag"
                        size="x-small"
                        color="primary"
                >
                    Save
                </custom-button>
            </div>
            </div>
        </div>
      </div>
        <div v-if="alertSuccess" :class="d['modal-msuccess']" @click="closeDialog">
            <div :class="d['modal-content']" @click.stop>
                <h3>{{ alertMassage }}</h3>
            </div>
        </div>
        <div v-if="alertError" :class="d['modal-merror']" @click="closeDialog">
            <div :class="d['modal-content']" @click.stop>
                <h3>{{ alertMassage }}</h3>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import moment from "moment";
export default {
  components: {
  },
  data() {
    return {
      loading: false,
      tags: [],
        aiTags: [],
      searchQuery: '',
      flatTags: [],
      selectedId: null,
      checkTags: [],
        showChecked: false,
        alertSuccess: false,
        alertError: false,
        alertMassage: '',
    };
  },
  watch: {
      'item.id': {
          handler() {
              this.getResponseTags();
          },
          immediate: true,
      },
  },
  props: {
    item: {
      type: Object,
    },
    isAdmin: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
      this.getResponseTags();
  },
  computed: {
    filteredTags() {
        return this.flatTags.filter(item => {
            if(this.showChecked){
                const checkMatch = this.checkTags.includes(item.id);

                const childrenMatches = item.children && item.children.some(child =>
                    this.checkTags.includes(child.id)
                );
                item.showChildren = true;

                return checkMatch || childrenMatches;
            }else {
                const isMatch = item.name.toLowerCase().includes(this.searchQuery.toLowerCase());

                const childrenMatches = item.children && item.children.some(child =>
                    child.name.toLowerCase().includes(this.searchQuery.toLowerCase())
                );
                if(this.searchQuery) {
                    item.showChildren = true;
                }

                return isMatch || childrenMatches;
            }
      });
    },
  },
  methods: {
    ...mapActions({
        getFeedAndTagList: "tags/getFeedAndTags",
        relatFeedAndTagList: "tags/relatFeedAndTags",
    }),
    closeDialog() {
      this.$emit("closeDialog");
    },
    async getResponseTags() {
        this.aiTags = [];
        this.tags = [];
        this.flatTags = [];

        const payload = {
            socialId: this.item.id
        };
      const response = await this.getFeedAndTagList(payload);
      if (response.status === 200) {
        const responseData = response.data.data;
        this.formatingResponse(responseData);
        this.flatTags = this.flattenTags(this.tags);
      } else {
        return response;
      }
    },
    formatingResponse(response) {
        this.totalPages = response.totalPages;
        const content = response.tagList;
        const aiContent = response.aiTagList;
        this.tags = content.map((res, index) => {
            return {
                parentId: res.parentId,
                name: res.name,
                description: res.description,
                createAt: res.createAt,
                isTagged: res.isTagged,
                platform: res.platform,
                id: res.id,
                no: index + 1,
            };
        });
        if (aiContent) {
            this.aiTags = aiContent.map((res, index) => {
                return {
                    parentId: res.parentId,
                    name: res.name,
                    description: res.description,
                    createAt: res.createAt,
                    isTagged: res.isTagged,
                    prob: res.prob,
                    platform: res.platform,
                    id: res.id,
                    no: index + 1,
                };
            }).sort((a, b) => b.prob - a.prob);
        }
    },
    flattenTags(tags) {
      const result = [];
      const map = new Map();
      this.checkTags = [];

      tags.forEach(tag => {
        map.set(tag.id, {...tag, children: [], showChildren: false});
        if(tag.isTagged) {
            this.checkTags.push(tag.id);
        }
      });

      tags.forEach(tag => {
        if (tag.id < 1000) {
          result.push(map.get(tag.id));
        } else {
          const prefix = tag.id.toString().slice(0, tag.id.toString().length - 3);
          const parent = map.get(Number(prefix));
          if (parent) {
            parent.children.push(map.get(tag.id));
          }
        }
      });

      const flatten = (items, level = 0) => {
        return items.reduce((acc, item) => {
          acc.push({...item, level});
          if (item.children && item.children.length) {
            acc = acc.concat(flatten(item.children, level + 1));
          }
          return acc;
        }, []);
      };
      return flatten(result);
    },
    toggleSelection(tag) {
        if (event.target.tagName === 'INPUT') {
            return; // Do nothing if the checkbox was clicked
        }

      if (this.selectedId === tag.id) {
        this.selectedId = null;
      } else {
        this.selectedId = tag.id;
      }
      this.$set(tag, 'showChildren', !tag.showChildren);
      if(this.saveType === 2) {
        this.tableData = [{id: '', name: ''}];
      }
    },
      handleCheckboxChange(event, value) {
          const isChecked = event.target.checked;

          if (isChecked) {
              if (!this.checkTags.includes(value)) {
                  this.checkTags.push(value);
              }
          } else {
              const index = this.checkTags.indexOf(value);
              if (index > -1) {
                  this.checkTags.splice(index, 1);
              }
          }
      },
      async relatFeedAndTag() {
          this.loading = true;
          if(!(this.checkTags && this.checkTags.length > 0)){
              this.loading = false;
              this.alertError = true;
              this.alertMassage = 'Please check the tags';
              setTimeout(() => {
                  this.alertError = false;
                  this.alertMassage = '';
              }, 1000);
              return ;
          }

          this.tags.forEach(tag => {
              tag.isTagged = this.checkTags.includes(tag.id);
          });
          const payload = {
              feedId: "",
              socialId: this.item.id,
              tagList: this.tags,
          };
          const response = await this.relatFeedAndTagList(payload);
          if (response.status === 200) {
              this.alertSuccess = true;
              this.selectedId = "";
              this.alertMassage = 'Tagging Success';
              setTimeout(() => {
                  this.alertSuccess = false;
                  this.alertMassage = '';
              }, 1000);
              this.loading = false;
          } else {
              this.selectedId = "";
              this.alertError = true;
              this.alertMassage = 'Tagging Failed';
              setTimeout(() => {
                  this.alertError = false;
                  this.alertMassage = '';
              }, 1000);
              this.loading = false;
          }
      },
      toggleFilter() {
          this.showChecked = !this.showChecked;
      },
  },
};
</script>

<style src="../style.scss" lang="scss" module="d"></style>
